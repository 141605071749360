export const primary = '#e7342c'
export const secondary = '#24ddaf'


export const white = '#FFFFFF'
// export const grayUltraLight = '#FCFBFA'
// export const grayExtraLight = '#F5F6F7'
export const grayLight = '#d1d7db'
export const gray = '#767676'
// export const grayDark = '#2D3747'
export const grayExtraDark = '#1D2330'
// export const dark = '#13161F'
export const text = '#333333'
// export const white = '#FFFFFF'
export const blue = '#092c4f'
// export const grayLight = ''