import * as modes from './modes'
import styles from './styles'
import { darken } from '@theme-ui/color'

const sharedButtonStyles = {
    border: `none`,
    color: `white`,
    cursor: `pointer`,
    fontFamily: `body`,
    fontWeight: `medium`,
    transition: `all 0.3s ease-in-out`,
    outline: `none`,
}
export default {
    "borderWidths": {
        "0": "0",
        "2": "2px",
        "4": "4px",
        "8": "8px",
        "px": "1px"
    },
    colors: {
        ...modes.light,
        modes: {
            dark: modes.dark,
        },
    },
    fonts: {
        body: `"MuseoSansRounded",  -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
        heading: `"MuseoSansRounded", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
        monospace: 'MuseoSansRounded',
    },
    fontSizes: [14, 16, 20, 24, 28, 32],
    lineHeightStep: [16, 32, 42],
    fontWeights: {
        body: 500,
        heading: 900,
        bold: 900,
    },
    lineHeights: {
        body: 1.5,
        // heading: 1.125,
    },
    letterSpacings: {
        body: 'normal',
        caps: '0.2em',
    },
    text: {
        heading: {
            fontSize: 4,
            padding: `0px`,
            color: `primary`,
        },
        display: {
            fontFamily: 'heading',
            fontWeight: 'heading',
            lineHeight: 'heading',
            letterSpacing: 'heading',
            fontSize: [ 1,2,3 ],
        },
        caps: {
            textTransform: 'uppercase',
            letterSpacing: 'caps',
        },
    },
    space: [0, 4, 8, 16, 20, 32, 48, 64, 80, 100],
    radii: {
        square: 0,
        radius: 4,
        rounded: 10,
        full: 32,
    },
    breakpoints: [`600px`, `900px`, `1200px`, `1800px`],

    container: {
        maxWidth: `1170px`,
        padding: [4, 4, 2],
        margin: "0 auto",
    },
    buttons: {
        primary: {
            ...sharedButtonStyles,
            borderRadius: `radius`,
            // backgroundImage: (t) => `linear-gradient(45deg, ${t.colors.indigo[6]}, ${t.colors.indigo[4]})`,
            backgroundColor: `primary`,
            fontSize: [0,1],
            px: 4,
            py: `0.6rem`,
            textDecoration: `none`,
            boxShadow: (t) => `0px 10px 15px ${t.colors.shadow}`,
            "&:hover": {
                transform: `translateY(-2px)`,
                boxShadow: (t) => `0px 20px 25px ${t.colors.shadow}`,
            },
        },
        secondary: {
            ...sharedButtonStyles,
            borderRadius: `full`,
            // backgroundImage: (t) => `linear-gradient(45deg, ${t.colors.gray[7]}, ${t.colors.gray[5]})`,
            backgroundColor: (t) => t.colors.skyBlue,
            fontSize: 1,
            px: 4,
            py: 1,
            boxShadow: `default`,
            "&:hover": {
                transform: `translateY(-1px)`,
            },
        },
        rounded: {
            ...sharedButtonStyles,
            backgroundColor: `primary`,
            borderWidth: 1,
            borderColor: `primary`,
            borderStyle: `solid`,
            borderRadius: `full`,
            textAlign: `center`,
            fontSize: 1,
            fontWeight: `900`,
            px: 8,
            py: `0.7rem`,
            "&:hover": {
                backgroundColor: darken("primary", 0.05),
                color: `white`,
            },
        },
        blue: {
            ...sharedButtonStyles,
            width: '100%',
            backgroundColor: `blue`,
            borderWidth: 1,
            borderColor: `blue`,
            borderStyle: `solid`,
            borderRadius: `radius`,
            textAlign: `center`,
            fontSize: 1,
            fontWeight: `900`,
            px: 8,
            py: `0.8rem`,
            "&:hover": {
                backgroundColor: darken("blue", 0.05),
                color: `white`,
                cursor: 'pointer',
            },
        },
        arrow: {
            ...sharedButtonStyles,
            backgroundColor: `transparent`,
            borderWidth: 1,
            borderColor: `primary`,
            borderStyle: `none`,
            borderRadius: `full`,
            textAlign: `center`,
            fontSize: 4,
            fontWeight: `semi900`,
            color: 'secondary',
            px: 2,
            py: 0,
            "&:hover": {
                color: darken("secondary", 0.05),
                transform: 'translateX(-16px)',
            },
        },
    },
    cards: {
        primary: {
            backgroundColor: 'transparent',
            mx: 1,
            border: '1px solid',
            borderColor: 'blue',
            boxSizing: 'border-box',
            position: 'relative',
            zIndex: 1,
            cursor: 'pointer',
            "&:hover": {
                border: '1px solid',
                borderColor: 'secondary',
                ":before": {
                    content: '""',
                    position: 'absolute',
                    zIndex: 0,
                    top: '-2px',
                    left: '-2px',
                    right: '-2px',
                    bottom: '-2px',
                    border: '2px solid',
                    borderRadius: 'rounded',
                    borderColor: 'secondary',
                },
            },
        },
        secondary: {
            backgroundColor: 'transparent',
            mx: 1,
            border: 'none',
            "&:hover": {
                border: 'none',
                cursor: 'default',
                ":before": {
                    display: 'none',
                },
            }
        },
    },
    forms:{
        label:{
            primary:{
                fontWeight: `900`,
                fontSize: 0,
            },
            secondary:{
                fontSize: 0,
            },
        },
        input: {
            primary: {
                my: 1,
                px: 3,
                py: `0.6rem`,
                fontSize: 1,
                borderRadius: `default`,
                boxShadow: `default`,
                border: `1`,
                outline: `none`,
                "&:focus": {
                    borderColor:'secondary',
                },
            },
            white: {
                width: '100%',
                my: 3,
                px: 3,
                py: `0.8rem`,
                fontSize: 1,
                borderRadius: `default`,
                boxShadow: `default`,
                backgroundColor: 'white',
                borderColor : 'white',
                border: `1`,
                outline: `none`,
                "&:focus": {
                    borderColor:'secondary',
                },
            },
        },
        radio:{
            color: 'secondary',
            fill: 'secondary',
        },
        checkbox:{
            "&:focus": {
                borderColor:'secondary',
            },
        },
    },


    styles,

}