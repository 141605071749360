// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-od-landings-js": () => import("./../../../src/pages/od-landings.js" /* webpackChunkName: "component---src-pages-od-landings-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/templates/landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */)
}

