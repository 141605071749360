import * as colors from './colors'

export const light = {
    ...colors,
    primary: colors.primary,
    text: colors.text,
    muted: colors.gray,
    link: colors.primary,
    // background: colors.backgroundLight,
    backgroundThemes: colors.white,
    border: colors.grayLight,

}

export const dark = {
    ...colors,
    primary: colors.primary,
    text: colors.text,
    muted: colors.gray,
    link: colors.primary,
    // background: colors.backgroundLight,
    backgroundThemes: colors.white,
    border: colors.grayLight,

}
