import { lighten } from '@theme-ui/color'

const styles = {
    Container: {
        p: 3,
        maxWidth: 1280,
    },
    root: {
        fontSize: 1,
        color: 'text',
        bg: 'white',
        fontFamily: `body`,
    },
    a: {
        color: 'blue',
        textDecoration: 'none',
        '&:hover': {
            color: 'blue',
            textDecoration: 'none',
        },
    },
    b: {
        color: 'blue',
        textDecoration: 'underline',
        '&:hover': {
            color: 'blue',
            textDecoration: 'underline',
        },
    },
    ba: {
        color: 'red',

        '&:hover': {
            color: 'pink',
        },
    },

    h1: {
        fontSize: 4,
        lineHeight: 1.5,
        color: `primary`,
    },
    h2: {
        fontSize: 3,
        color: 'blue',
    },
    h3: {
        fontSize: 3,
        fontWeight: 'normal',
        color: 'blue',
    },
    h4: {
        fontSize: 3,
    },
    h5: {
        fontSize: 2,
        color: 'white',
    },
    h6: {
        fontSize: 1,
    },
    li: {
        marginBottom: 1,
        py: 2,
        color: `primary`,
    },
    step:{
        px:2,
        py:2,
        fontSize: 0,
        fontWeight: `900`,
    },
    info:{
        px:2,
        py:3,
        my: 4,
        fontSize: 1,
        borderRadius: 'radius',
        backgroundColor: lighten('secondary', 0.45),
    },
    gray:{
        px:2,
        py:3,
        my: 4,
        borderRadius: 'radius',
        backgroundColor: 'grayLight',
    },
    small:{
        px:2,
        py:3,
        my: 4,
        fontSize: 0,
        borderRadius: 'radius',
    },
    blockquote: {
        my: 4,
        mx: 0,
        py: 3,
        px: 4,
        bg: 'blockquote.bg',
        borderLeft: t => `5px solid ${t.colors.blockquote.border}`,
        color: 'blockquote.color',
        fontStyle: 'italic',
        '> p': {
            m: 0,
        },
    },

    table: {
        width: '100%',
        my: 4,
        borderCollapse: 'separate',
        borderSpacing: 0,
        [['th', 'td']]: {
            textAlign: 'left',
            py: '4px',
            pr: '4px',
            pl: 0,
            borderColor: 'muted',
            borderBottomStyle: 'solid',
        },
    },
    th: {
        verticalAlign: 'bottom',
        borderBottomWidth: '2px',
    },
    td: {
        verticalAlign: 'top',
        borderBottomWidth: '1px',
    },
    hr: {
        border: 0,

    },
    footer: {
        backgroundColor: 'blue',
        color: 'white',
        a:{
            color: 'white',
        }
    },
}
export default styles
